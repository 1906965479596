
.icon-button-expand {
  text-align: center;
}

.icon-button-expand .MuiIconButton-root {
  background-color: rgba(13, 125, 236, 0.7);
  /* background-color: rgb(247, 215, 215, 0.8); */
}

.icon-button-expand .MuiIconButton-root:hover,
.icon-button-expand .MuiIconButton-root:focus {
  background-color: rgb(13, 125, 236);
  /* background-color: rgb(247, 215, 215, 0.8); */
}

.icon-button-expand-animate {
  background-size: contain;
  -webkit-animation: iconButtonExpandAnimate 0.5s infinite ease-in-out alternate;
          animation: iconButtonExpandAnimate 0.5s infinite ease-in-out alternate;
}

@-webkit-keyframes iconButtonExpandAnimate {
  0% {
    -webkit-transform: scale(1);
            transform: scale(1);
  }
  100% {
    -webkit-transform: scale(1.2);
            transform: scale(1.2);
  }
}

@keyframes iconButtonExpandAnimate {
  0% {
    -webkit-transform: scale(1);
            transform: scale(1);
  }
  100% {
    -webkit-transform: scale(1.2);
            transform: scale(1.2);
  }
}


ul.no-marker {
  margin-left: 0;
  padding-left: 0;
}

.no-marker li {
  list-style-type: none;
  position: relative;
  margin-left: 25px;
  padding: 4px;
}

.no-marker li::before {
  content: '';
  position: absolute;
  top: calc(50% - 10px);
  left: -25px;
  height: 20px;
  width: 20px;
  background-image: url(../imgs/checked_ico.png);
  background-repeat: no-repeat;
  background-size: contain;
}

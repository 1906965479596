
/* .help-layout {
  position: fixed;
  top: 0;
  left: 0;
  height: 100%;
  width: 100%;
  background-color: rgba(0, 0, 0, 0.5);
  opacity: 0;
  z-index: -1;
  -webkit-transition-duration: .5s;
          transition-duration: .5s;
}

.help-layout.active {
  opacity: 1;
  z-index: 500;
}

.help {
  position: fixed;
  background-color: #fff;
  border-radius: 5px;
  width: 100%;
  max-width: 550px;
  height: 250px;
  bottom: 150px;
  right: -100%;
  -webkit-transition-duration: 0.5s;
          transition-duration: 0.5s;
  -webkit-box-shadow: 0 0 10px black;
          box-shadow: 0 0 10px black;
}

.help .callback {
  -webkit-box-align: center;
      -ms-flex-align: center;
          align-items: center;
}

.help.active {
  right: 20px;
} */

.help-button {
  background-color: #FFDD00;
  cursor: pointer;
  height: 50px;
  width: 50px;
  border-radius: 50%;
  position: fixed;
  /* bottom: 75px; */
  bottom: 112px;
  right: 50px;
  background: #FFDD00 url("../imgs/phone.svg");
  background-repeat: no-repeat;
  background-size: contain;
  -webkit-animation: helpButtonAnimate 0.5s infinite ease-in-out alternate;
          animation: helpButtonAnimate 0.5s infinite ease-in-out alternate;
}

@-webkit-keyframes helpButtonAnimate {
  0% {
    -webkit-transform: scale(1);
            transform: scale(1);
  }
  100% {
    -webkit-transform: scale(1.2);
            transform: scale(1.2);
  }
}

@keyframes helpButtonAnimate {
  0% {
    -webkit-transform: scale(1);
            transform: scale(1);
  }
  100% {
    -webkit-transform: scale(1.2);
            transform: scale(1.2);
  }
}

/* @media (max-width: 767px) {
  .help-button {
    display: none;
  }
} */


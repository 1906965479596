
.addr ul {
  margin-left: 0;
  padding-left: 0;
}

.addr li {
  list-style-type: none;
}

.addr li.pd-l {
  padding-left: 19px;
}
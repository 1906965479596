
/* footer {
    margin-left: 10px;
    margin-right: 10px;
} */

/* @media (min-width: 768px)
.navbar-fixed-bottom {
    border-radius: 0;
} */

.text-center {
    text-align: center;
}

.navbar-footer {
    min-height: 40px;
    background-color: #f0f0f0;
    border-color: #e7e7e7;
}

.navbar-fixed-bottom {
    bottom: 0;
    margin-bottom: 0;
}

.navbar-fixed-bottom {
    position: fixed;
    right: 0;
    left: 0;
    border-width: 0 0 1px;
}

.text-pt {
    padding-top: 5px;
}

.hidden-sm {
    display: block !important;
}

.hidden-xs {
    display: block !important;
}

.ya-share2, 
.ya-share2 * {
    line-height: normal;
}
